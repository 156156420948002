import React, { useState } from 'react';
import './css/index.css'; // Import your CSS
import Logo from './assets/images/icon-dark-theme.png';

const App = () => {
  const [isMobileNavActive, setIsMobileNavActive] = useState(false);

  const toggleMobileNav = () => {
    setIsMobileNavActive(!isMobileNavActive);
  };

  return (
    <>
      <header>
        <a href="/" className="logo">CODESHARK</a>
        <nav className="pc-nav">
          <ul>
            <li><a href="#home-section">Home</a></li>
            <li><a href="#about-section">About</a></li>
            <li><a href="#contact-section">Contact</a></li>
            <li><a href="/auth" className="login-button">Login</a></li>
          </ul>
        </nav>

        <nav className={`mobile-nav ${isMobileNavActive ? 'active' : ''}`}>
          <ul>
            <li><a href="#home-section">Home</a></li>
            <li><a href="#about-section">About</a></li>
            <li><a href="#contact-section">Contact</a></li>
            <li><a href="/auth" className="login-button">Login</a></li>
          </ul>
        </nav>
        <button className="mobile-nav-toggle" onClick={toggleMobileNav}>
          <i className="fas fa-bars"></i>
        </button>
      </header>

      <section id="home-section">
        <div className="left-info">
          <div className="info-script">
            <h1>CODESHARK</h1>
            <p>Learn to code with us</p>
            <a href="#about-section" className="btn-more">Learn More</a>
          </div>
        </div>
        <div className="right-info">
          <img src={Logo} alt="Info" />
        </div>
      </section>

      <section id="about-section">
        <div className="about-content">
          <h2>About Us</h2>
          <p>
            CODESHARK is your trusted resource for learning how to code. Our mission
            is to provide accessible coding education to everyone. Join us today and
            kickstart your coding journey with practical guides, projects, and support
            from the community.
          </p>
        </div>
      </section>

      <section id="contact-section">
        <h2>Contact Us</h2>
        <form>
          <label htmlFor="name">Name:</label>
          <input type="text" id="name" placeholder="Your name" required />

          <label htmlFor="email">Email:</label>
          <input type="email" id="email" placeholder="Your Email" required />

          <label htmlFor="message">Message:</label>
          <textarea id="message" placeholder="Your Message" required></textarea>

          <button type="submit">Send</button>
        </form>
      </section>
    </>
  );
};

export default App;
