import React, { useState } from 'react';
import './css/authentication.css'; // Ensure the CSS file is placed correctly in your project
import poster from "../assets/images/logo.jpg";

const Authentication = () => {
  const [isLoginFormVisible, setIsLoginFormVisible] = useState(true);

  const toggleForm = () => {
    setIsLoginFormVisible(!isLoginFormVisible);
  };

  return (
    <div className="container-auth">
      <div className="authentication">
        <div className="auth-logo">
          <h1>Welcome to CODESHARK</h1>
        </div>
        <div className="form-auth">
          <div className="poster">
            <img src={poster} alt="poster" />
            <div className="description">
              <h3>Maecenas mattis egestas</h3>
              <p>Erdum et malesuada fames ac ante ipsum primis in faucibus uspendisse porta.</p>
            </div>
          </div>
          {isLoginFormVisible ? (
            <div id="login-form">
              <h2 className="title">Login</h2>
              <form id="auth-login-form">
                <label htmlFor="login-email">Email:</label>
                <input type="email" id="login-email" name="email" placeholder="Your Email" />
                <label htmlFor="login-password">Password:</label>
                <input type="password" id="login-password" name="password" placeholder="Your Password" />
                <button type="button">Login</button>
                <ul>
                  <li>
                    Don't have an account?{' '}
                    <button onClick={toggleForm} className="link-button">
                      Register
                    </button>
                  </li>
                  <li>
                    <a href="#forgot-password">Forgot password?</a>
                  </li>
                </ul>
              </form>
            </div>
          ) : (
            <div id="register-form">
              <h2 className="title">Register</h2>
              <form id="auth-register-form">
                <label htmlFor="register-email">Email:</label>
                <input type="email" id="register-email" name="email" placeholder="Your Email" />
                <label htmlFor="register-password">Password:</label>
                <input type="password" id="register-password" name="password" placeholder="Your Password" />
                <button type="button">Register</button>
                <p>
                  Already have an account?{' '}
                  <button onClick={toggleForm} className="link-button">
                    Login
                  </button>
                </p>
              </form>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Authentication;
