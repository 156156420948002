import React from 'react';
import './css/dashboard.css'; // Import your CSS
import poster from "../assets/images/logo.jpg";

const Dashboard = () => {
  return (
    <div className="container-dashboard">
      <nav className="navigation">
        <div className="logo">
          <h1>CODESHARK</h1>
        </div>
        <ul>
          <li><a href="#option1">Option</a></li>
          <li><a href="#option2">Option</a></li>
          <li><a href="#option3">Option</a></li>
        </ul>
      </nav>
      <div className="dashboard">
        <header id="header-dashboard">
          <form id="search-form">
            <input type="text" placeholder="Search" aria-label="Search" />
          </form>
          <ul>
            <li><button className="lang" aria-label="Language option">LANG</button></li>
            <li><button className="messages" aria-label="View Messages"><i className="fa fa-envelope" aria-hidden="true"></i></button></li>
            <li><button className="notifications" aria-label="View Notifications"><i className="fa fa-bell" aria-hidden="true"></i></button></li>
            <li><button className="profile" aria-label="View Profile" id="profile-option"><i className="fa fa-user" aria-hidden="true"></i>Username</button></li>
          </ul>
        </header>
        <div className="main-panel">
          <div className="left-panel">
            <div className="news-user">
              <div className="news">
                <h3>Hello User!</h3>
                <p>Here are some news for you</p>
                <button className="review-link" onClick={() => window.location.href='#review'}>review it</button>
              </div>
              <div>
                <img src={poster} alt="info" />
              </div>
            </div>
            <div className="dashboard-info">
              <section id="performance">
                <h3>The Best Lessons:</h3>
                <div className="score-performance">
                  <div className="score">
                    <h2>94.5</h2>
                    <p>Introduction to programming</p>
                  </div>
                  <button className="all-lessons" onClick={() => window.location.href='#all-lessons'}>All lessons</button>
                </div>
                <div className="graph-display">
                  <ul className="graph">
                    <li>
                      <div className="graph-bar">
                        <p className="lessons-scores">85.3</p>
                        <div className="bar">
                          <div className="fill"></div>
                        </div>
                        <div className="lessons-title">Algorithms Structures</div>
                      </div>
                    </li>
                    <li>
                      <div className="graph-bar">
                        <p className="lessons-scores">85.3</p>
                        <div className="bar">
                          <div className="fill"></div>
                        </div>
                        <div className="lessons-title">Algorithms Structures</div>
                      </div>
                    </li>
                    <li>
                      <div className="graph-bar">
                        <p className="lessons-scores">85.3</p>
                        <div className="bar">
                          <div className="fill"></div>
                        </div>
                        <div className="lessons-title">Algorithms Structures</div>
                      </div>
                    </li>
                  </ul>
                </div>
              </section>
              <section id="last-visit"></section>
            </div>
          </div>
          <div className="right-panel">
            <div className="calendar">
              <div className="header-calendar">
                <div className="calendar-title">
                  <h3>Calendar</h3>
                  <p className="last-event">6 events today</p>
                </div>
                <button className="today-review" onClick={() => window.location.href='#today-events'}>Today</button>
              </div>
              <div className="last-event-card">
                <div className="time-uptodate">
                  <i className="fa fa-calendar" aria-hidden="true"></i>
                  <p>10:00</p>
                </div>
                <div className="event-card">
                  <div className="title-card">
                    <h3>Meeting with the team</h3>
                    <p>10:00 - 11:00</p>
                  </div>
                </div>
              </div>
              <ul>
                <li>
                  <div className="item-event-card">
                    <div className="time-uptodate">
                      <i className="fa fa-calendar" aria-hidden="true"></i>
                      <p>10:00</p>
                    </div>
                    <div className="event-card">
                      <div className="title-card">
                        <h3>Meeting with the team</h3>
                        <p>10:00 - 11:00</p>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="item-event-card">
                    <div className="time-uptodate">
                      <i className="fa fa-calendar" aria-hidden="true"></i>
                      <p>10:00</p>
                    </div>
                    <div className="event-card">
                      <div className="title-card">
                        <h3>Meeting with the team</h3>
                        <p>10:00 - 11:00</p>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
