import React from 'react';

const Error404 = () => (
  <section className="error-404">
    <h1>404 - Page Not Found</h1>
    <p>Sorry, the page you are looking for does not exist.</p>
    <button className="btn-home" onClick={() => window.location.href='/'}>Go Back Home</button>
  </section>
);

export default Error404;
